/*
   Voorbeeldgebruik in blade:

   <confirm-modal
       button-text="{{ __('Delete') }}"
       button-class="btn btn-danger"
       confirm-text="{{ __('Confirm') }}"
       cancel-text="{{ __('Cancel') }}"
       title-text="{{ __('Confirm') }}"
       body-text="{{ __('Please confirm') }}"
       submit-route="/admin/map/delete"
       submit-form="filter-form"
       csrf="{{ csrf_token() }}">
   </confirm-modal>
*/

<template>
    <div>
        <button
            :class="buttonClass"
            v-on:click="openModal"
            data-toggle="modal"
            data-target="#confirm-modal">
            {{ buttonText }}
        </button>

        <div v-if="showModal" id="confirm-modal" class="modal fade text-danger" role="dialog">
            <div class="modal-dialog ">
                <form action="" id="confirm-form" method="post">
                    <input type="hidden" name="_token" :value="csrf">
                    <div class="modal-content">
                        <div class="modal-header bg-danger">
                            <h4 class="modal-title text-white">{{ titleText }}</h4>
                            <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p class="text-dark">{{ bodyText }}</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-success" data-dismiss="modal">{{ cancelText }}</button>
                            <button v-on:click="submitModal" type="submit" name="" class="btn btn-danger" data-dismiss="modal">{{ confirmText }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            buttonText : {
                type: String,
                required: true
            },
            buttonClass : {
                type: String,
                default: 'btn btn-primary'
            },
            confirmText : {
                type: String,
                default: 'Confirm'
            },
            cancelText : {
                type: String,
                default: 'Cancel'
            },
            titleText : {
                type: String,
                default: 'Confirm'
            },
            bodyText : {
                type: String,
                default: 'Please confirm this action'
            },
            submitRoute : {
                type: String,
                default: ''
            },
            submitForm : {
                type: String,
                default: ''
            },
            csrf : {
                type: String,
                required: true
            }
        },
        data() {
            return {
                showModal: false
            }
        },
        methods: {
            openModal : function(e) {
                this.showModal = true;
            },
            submitModal : function(e) {
                debugger;
                if (this.submitForm && this.submitRoute) {
                    $('#' + this.submitForm).attr('action', this.submitRoute);
                    $('#' + this.submitForm).attr('method', 'post');
                    $('#' + this.submitForm).submit();
                } else if(this.submitRoute) {
                    // Submit het form
                    $('#confirm-form').submit();
                }
            }

        },
        updated() {
            // Als het modal in het dom aanwezig is kan
            // de action van het form ingevuld worden.
            // Koppel de hidden.bs.modal event
            // waarmee het modal weer verwijderd wordt.

            if (this.showModal) {
                // Vul route in form action
                $('#confirm-form').attr('action', this.submitRoute);

                // Koppel de hidden.bs.modal event om het form te verwijderen
                // nadat het verborgen is
                let _this = this;
                $('#confirm-modal').on('hidden.bs.modal', function (e) {
                    _this.showModal = false;
                });
            }
        }
    }
</script>
