<template>
    <a href="#"
       :title="[isFavorite ? this.removeText : this.addText]"
       data-toggle-tt="tooltip"
       v-on:click="setFavorite">
        <span :class="[isFavorite ? 'mdi mdi-18px mdi-star' : 'mdi mdi-18px mdi-star-outline']"></span>
        {{ isFavorite ? this.removeText : this.addText }}
    </a>
</template>

<script>
    export default {
        props: ['ri', 'oldFavorite', 'id'],
        data() {
            return {
                toolTip: null,
                counter: 0,
                isFavorite: this.oldFavorite === "1",
                removeText: lang('Remove from Favorites'),
                addText: lang('Add to Favorites')
            }
        },
        updated() {
            tpmResetTooltips();
        },
        methods: {
            setFavorite : function(e) {
                e.preventDefault();
                axios.post('/tpmapi', {
                    ri: this.ri,
                    favorite: !this.isFavorite,
                    id: this.id})
                    .then(response => {
                        this.isFavorite = !this.isFavorite;
                    })
                    .catch(function (error) {
                        //console.log(error);
                    });
            }
        }
    }
</script>
