/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

// json language file includen. Deze files worden gebruikt door de
// lang() functie in topomania.js, en is in alle javascript beschikbaar.
window.trans = [];
window.trans['nl'] = require('../../resources/lang/nl.json');
window.trans['en'] = require('../../resources/lang/en.json');

require('./topomania');

window.Vue = require('vue');
Vue.config.productionTip = false;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('favorite-action', require('./components/FavoriteAction').default);
Vue.component('confirm-modal', require('./components/ConfirmModal').default);
Vue.component('check-all', require('./components/CheckAllCheckbox').default);
Vue.component('sort-link', require('./components/SortLink').default);
Vue.component('mymaps-reorder-link', require('./components/MyMapsReorderLink').default);
Vue.component('batchprocess', require('./components/BatchProcess').default);
Vue.component('dashboard-reorder', require('./components/DashboardReorder').default);
Vue.component('invitationcode', require('./components/InvitationCode').default);
Vue.component('register-page-member', require('./components/RegisterPageMember').default);
Vue.component('toggle_account_type', require('./components/ToggleAccountType').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});

tpmInitPage();

