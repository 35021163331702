<template>
    <div class="row">
        <div class="col-md-auto">
            <a href="javascript:void(0)"
               :title="[isReordering ? this.saveOrderText : this.startReorderText]"
               v-on:click="toggleReordering">
                <span :class="[isReordering ? 'mdi mdi-18px mdi-content-save-outline' : 'mdi mdi-18px mdi-reorder-horizontal']"></span>
                {{ isReordering ? this.saveOrderText : this.startReorderText }}
            </a>
        </div>
        <div v-if="isReordering" class="col-md-auto">
            <a href="javascript:void(0)"
               :title="this.cancelReorderText"
               v-on:click="cancelReordering">
                <span class="mdi mdi-18px mdi-cancel"></span>
                {{ cancelReorderText }}
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['pageId', 'startReorderText', 'saveOrderText', 'cancelReorderText'],
        data() {
            return {
                isReordering: false,
                undoCache: null
            }
        },
        methods: {
            toggleReordering: function(e) {
                this.isReordering = !this.isReordering;
                if (this.isReordering) {
                    this.startReordering();
                } else {
                    this.saveOrder();
                }
            },
            startReordering : function(e) {
                // Bewaar een clone voor undo bij cancel
                this.undoCache = $("#mypage-maps").clone();

                this.addSortable();
            },
            saveOrder : function(e) {
                this.removeSortable();

                let newOrder = [];

                let cats = jQuery(".category");
                for (let i = 0; i < cats.length; i++ ) {
                    let maps = jQuery(cats[i]).find("tr");
                    let mapList = [];
                    for (let j = 0; j < maps.length; j++ ) {
                        if (maps[j].id) {
                            newOrder.push({
                                publishedmap_id: maps[j].id,
                                category: cats[i].id,
                                catweight: i,
                                mapweight: j
                            });
                        }
                    }
                }

                axios.post('/tpmapi', {
                    ri: 6,
                    pi: this.pageId,
                    data: JSON.stringify(newOrder)});
            },
            cancelReordering : function(e) {
                this.isReordering = false;
                this.removeSortable();

                // Zet de undoCache terug
                $("#mypage-maps").replaceWith(this.undoCache);
            },
            addSortable : function() {
                let rowHelper = function(e, ui) {
                    ui.children().each(function() {
                        $(this).width($(this).width());
                    });
                    return ui;
                };

                this.addDragHandle('map-handle', 'td.mapthumbnail');
                $(".category tbody").sortable(
                    {   connectWith: $('tbody'),
                        handle: '.map-handle',
                        helper: rowHelper,
                        placeholder: 'row-placeholder',
                        start: function (event, ui) {
                            ui.placeholder.html('<td colspan="2">&nbsp;</td>');}
                    });

                this.addDragHandle('cat-handle', '.cat-header');
                $(".categories").sortable({handle: '.cat-handle'});
            },
            removeSortable: function() {
                $(".category tbody").sortable("destroy");
                $(".categories").sortable("destroy");
                $(".map-handle").remove();
                $(".cat-handle").remove();
            },
            addDragHandle(className, prependTo) {
                let handle =
                    '<div class="' + className + '">' +
                    '<span class="mdi mdi-18px mdi-drag" aria-hidden="true"></span>' +
                    '</div>';

                $(prependTo).prepend(handle);

                $("." + className).css({
                    position: "absolute",
                    marginLeft: "7px",
                    marginTop: "2px",
                    cursor: "move",
                    zIndex: "100"
                });

            }
        }
    }
</script>
