<template>
    <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" id="accountType" @click="toggleAccountType($event)" v-model="isSchoolAccount" :disabled="isWorking">
        <label class="custom-control-label" for="accountType">{{isSchoolAccount ? this.schoolText : this.standardText}}</label>
    </div>
</template>

<script>
    export default {
        props: ['oldIsSchoolAccount', 'updateTextId'],
        data() {
            return {
                toolTip: null,
                isWorking: false,
                isSchoolAccount: this.oldIsSchoolAccount === "1",
                schoolText: '',
                standardText: ''
            }
        },
        created() {
            this.standardText = lang('Standard Account');
            this.schoolText = lang('School Account');
        },
        updated() {
            tpmResetTooltips();
        },
        methods: {
            toggleAccountType: function (e) {
                this.isWorking = true;
                let newIsSchoolAccount = e.target.checked;

                // Wijzig de waarde in de database
                axios.post('/tpmapi', {
                    ri: 'setaccounttype', sa: newIsSchoolAccount
                })
                    .then(response => {
                        this.handleResponse(response.data, newIsSchoolAccount);
                    })
                    .catch(function (error) {
                        this.handleResponse(0, newIsSchoolAccount);
                    });
            },
            handleResponse(succes, newIsSchoolAccount) {
                console.log(newIsSchoolAccount);
                if (succes) {
                    console.log('succes');

                    // refresh van de pagina moet omdat het menu opnieuw opgebouwd moet worden
                    location.reload();
                    /*
                    this.isSchoolAccount = newIsSchoolAccount;

                    // Update de tekst in de tabel met user gegevens
                    $('#' + this.updateTextId).text(this.isSchoolAccount ? this.schoolText : this.standardText);
                    */
                } else {
                    this.isSchoolAccount = !newIsSchoolAccount;
                    console.log(this.isSchoolAccount);
                }
                this.isWorking = false;
            }
        }
    }
</script>
