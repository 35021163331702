<template>

</template>

<script>
    export default {
        props: [],
        data() {
            return {
                oldOrder: []
            }
        },
        mounted() {
            // gridstrap JavaScript toevoegen aan document
            // het css zit in tpm.css
            // TODO: javascript toevoegen aan app.js
            let gridstrapJs = document.createElement('script');
            gridstrapJs.setAttribute('src', 'js/jquery.gridstrap.min.js');
            document.head.appendChild(gridstrapJs);

            window.addEventListener('load', () => {
                this.init();
            });
        },
        beforeDestroy() {
            window.removeEventListener('load', () => {
                this.init();
            });
            document.removeEventListener('mouseup', this.saveOrder);
        },
        methods: {
            init: function() {
                $("#dashboard").gridstrap({
                    swapMode: true,
                    dragCellHandleSelector: 'h3',
                    dragCellClass: 'dragging',
                    mouseOverCellClass: 'mouseover'
                });

                this.oldOrder = this.getOrder();
                document.addEventListener('mouseup', this.saveOrder);
            },
            getOrder: function() {
                let order = [];
                let cards = $("[data-cardid]");

                let cardList = [];
                for (let i = 0; i < cards.length; i++ ) {
                    if ($(cards[i]).parent().hasClass("gridstrap-cell-visible"))
                        continue;

                    order.push(cards[i].dataset.cardid);
                }
                return order;
            },
            saveOrder : function(e) {
                let newOrder = this.getOrder();
                if (newOrder.join() !== this.oldOrder.join()) {
                    axios.post('/tpmapi', {
                        ri: 8,
                        pi: this.pageId,
                        data: newOrder
                    });
                    this.oldOrder = newOrder;
                }
            }
        }
    }
</script>
