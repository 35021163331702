<template>
    <div>
        <div class="progress" style="height: 30px">
            <div class="progress-bar progress-bar-striped" role="progressbar" :style="{height: '30px', width: valueNow + '%'}" aria-valuenow="valueNow" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <button v-on:click="nextBatch(0)" type="button" :class="'btn btn-primary mt-3' + [working ? ' disabled' : '']">Start</button>
        <button v-on:click="stopBatch()" type="button" :class="'btn btn-danger mt-3' + [!working ? ' disabled' : '']">Stop</button>
        <div class="mt-4"></div>
        <table>
            <tr>
                <td style="text-align: right">
                    Processed
                </td>
                <td>
                    : {{ this.processed }}
                </td>
            </tr>
            <tr>
                <td style="text-align: right">
                    Succeeded
                </td>
                <td>
                    : {{ this.succeeded }}
                </td>
            </tr>
            <tr>
                <td style="text-align: right">
                    Failed
                </td>
                <td>
                    : {{ this.failed }}
                </td>
            </tr>
            <tr>
                <td style="text-align: right">
                    Time
                </td>
                <td>
                    : {{ this.timeStr(this.elapsedTime) }}
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
    export default {
        props: {
            action: {
                type: String,
                required: true
            },
            ids: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                valueNow: 0,
                working: false,
                stopped: false,
                processed: 0,
                failed: 0,
                succeeded: 0,
                time: 0,
                elapsedTime: 0,
                cancelTokenSource: null
            }
        },
        methods: {
            nextBatch: function(batchStart) {

                if (batchStart === 0) {
                    if (this.working) {
                        return;
                    }
                    this.working = true;
                    this.stopped = false;
                    this.processed = 0;
                    this.failed = 0;
                    this.succeeded = 0;
                    this.valueNow = 0;
                    this.elapsedTime = 0;
                    this.startTime = new Date();
                }

                let batchSize = 100;
                let batchEnd = Math.min(this.ids.length, batchStart + batchSize);
                let _this = this;

                if (batchEnd === batchStart) {
                    this.elapsedTime = new Date() - this.startTime;
                    setTimeout(() => {
                        _this.working = false;
                    }, 1000);
                    return;
                }

                let promises = [];
                let percInc = 100 / this.ids.length;

                const cancelToken = axios.CancelToken;
                this.cancelTokenSource = cancelToken.source();

                for (var i = batchStart; i < batchEnd; i++) {
                    if (this.stopped) {
                        break;
                    }
                    promises.push(
                        axios.post('/tpmapi', { ri: this.action, id: this.ids[i]},
                            { cancelToken: this.cancelTokenSource.token } )
                            .then(response => {
                                //console.log(response);
                                if (!this.stopped) {
                                    _this.processed++;
                                    this.elapsedTime = new Date() - this.startTime;

                                    if (response.data) {
                                        _this.succeeded++;
                                    } else {
                                        _this.failed++;
                                    }

                                    _this.valueNow += percInc
                                }
                            })
                            .catch((thrown) => {
                                if (axios.isCancel(thrown)) {
                                    this.working = this.working && false;
                                }
                            })
                    );
                }
                if (!this.stopped) {
                    this.executeBatch(promises, i, this.nextBatch)
                }
            },
            executeBatch: function(promises, batchStart, callback) {
                axios.all(promises)
                    .then(response => {
                        callback(batchStart);
                    });
            },
            timeStr: function(time) {
                var milliseconds = parseInt((time%1000)/100)
                    , seconds = parseInt((time/1000)%60)
                    , minutes = parseInt((time/(1000*60))%60)
                    , hours = parseInt((time/(1000*60*60))%24);

                hours = (hours < 10) ? "0" + hours : hours;
                minutes = (minutes < 10) ? "0" + minutes : minutes;
                seconds = (seconds < 10) ? "0" + seconds : seconds;

                return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
            },
            stopBatch: function () {
                this.stopped = true;
                this.cancelTokenSource.cancel('cancelled');
            }
        }
    }
</script>
