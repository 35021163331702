<template>
    <div v-if="!isMember">
        <a href="#"
           :title="this.joinTitle"
           data-toggle-tt="tooltip"
           v-on:click="codeDialog">
            <span :class="[isMember ? 'mdi mdi-18px mdi-account-check-outline' : 'mdi mdi-18px mdi-account-multiple-plus-outline']"></span>
            {{ this.joinText }}
        </a>
        <div id="code-dialog" class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ this.invitationCodeDialogTitle}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div>{{ this.explainTrans }}:</div>
                        <div class="form-group">
                            <div class="input-group">
                                <input type="text" class="form-control"
                                       id="invitation-code"/>
                            </div>
                            <small id="message" class="form-text text-muted">{{ this.message }}</small>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" v-on:click="this.joinPage" class="btn btn-primary">{{ this.joinText}}</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ this.cancelTrans }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <span class="mdi mdi-18px mdi-account-check-outline"></span>
        {{ this.isMemberText }}
    </div>
</template>

<script>
    import Template from "./Template";
    export default {
        components: {Template},
        props: ['pageId', 'isOldMember'],
        data() {
            return {
                isMember: this.isOldMember === "1",
                joinText: lang('Join Group'),
                joinTitle: lang('Become a member of this group'),
                isMemberText: lang('You are a member of this group'),
                explainTrans: lang('Enter the Invitation Code you received'),
                invitationCodeDialogTitle: lang('Enter Invitation Code'),
                invalidCodeTrans: lang('The entered code is not valid'),
                cancelTrans: lang('Cancel'),
                message: ' '
            }
        },
        mounted() {
            //console.log(this.isOldMember);
            //console.log(this.isMember);
        },
        updated() {
            tpmResetTooltips();
        },
        methods: {
            codeDialog : function(e) {
                $('#code-dialog').modal();
            },
            joinPage : function(e) {
                e.preventDefault();
                let code = $("#invitation-code").val();

                axios.post('/tpmapi', {
                    ri: 'registerpagemember',
                    pid: this.pageId,
                    ic: code})
                    .then(response => {
                        if (response.data.result == 1) {
                            console.log('valid');
                            this.isMember = true;
                            $('#code-dialog').modal('hide');
                        } else {
                            console.log('invalid ' + response.data.result);
                            this.message = this.invalidCodeTrans;
                        }
                    })
                    .catch(function (error) {
                        //console.log(error);
                    });
                tpmResetTooltips();
            }
        }
    }
</script>
