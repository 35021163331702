import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class Template extends Component {
    render() {
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-header">Example Component</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

/**
 * Attach to DOM
 */
const element = document.getElementById('r-template');
if (element) {
    // Haal de dataset op van het element en geef door door als props aan react
    const props = Object.assign({}, element.dataset);

    ReactDOM.render(<Template {...props} />, element);

    // Kan ook korter geschreven worden als:
    // ReactDOM.render(<MapList {...element.dataset} />, element);
}
