<template>
    <a href="javascript:void(0)" v-on:click="sort">{{ text }}</a>
</template>

<script>
    export default {
        props: {
            text: {
                // Tekst van de link
                type: String,
                required: true
            },
            sortName: {
                // kolomnaam waarop gesorteeerd moet worden
                type: String,
                required: true
            },
            sortNameId: {
                // id van input waar kolomnaam om op te sorteren (sortName) in wordt opgeslagen
                type: String,
                required: true
            },
            sortOrderId: {
                // id van input waar sort order in wordt opgeslagen
                type: String,
                required: true
            },
            submitId: {
                // id van submit knop
                type: String,
                required: true
            }
        },
        data() {
            return {
            }
        },
        methods: {
            sort : function(e) {
                e.preventDefault();

                // Toggle order als dit dezelfde link is waarop de order nu staat
                // (leeg is 'asc')
                if ($('#' + this.sortNameId).attr('value') === this.sortName) {
                    let order = $('#' + this.sortOrderId).attr('value');
                    if (!order) {
                        $('#' + this.sortOrderId).attr('value', 'desc');
                    } else {
                        $('#' + this.sortOrderId).attr('value', '');
                    }
                } else {
                    $('#' + this.sortOrderId).attr('value', '');
                }

                $('#' + this.sortNameId).attr('value', this.sortName);
                $('#' + this.submitId).submit();
            }
        }
    }
</script>
