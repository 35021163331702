/**
 * tpmInitPage
 * initalisatie voor nieuwe pagina's
 * Momenteel alleen gebruikt voor tooltips
 */
tpmInitPage = function() {
    // Tooltips
    //$(function () {
        $('[data-toggle-tt="tooltip"]').tooltip({delay: {show: 700, hide: 100}});

        // Hide alle tooltips bij onclick op tooltip element
        $('[data-toggle-tt="tooltip"]').click((e) => {$('[data-toggle-tt="tooltip"]').tooltip('hide')});
    //});
};

/**
 * tpmResetTooltips
 * Bij onClick javascript blijven de tooltips hangen.
 * tpmResetTooltips stelt alle tooltips opnieuw in met als
 * gevolg dat de hangende tooltip verdwijnt.
 * Wordt o.a. gebruikt in vue componenten
 */
tpmResetTooltips = function() {
    //$(function () {
        let tt = $('[data-toggle-tt="tooltip"]');
        tt.tooltip('dispose');
        tt.tooltip({delay: {show: 700, hide: 100}});
    //});
};

/**
 * lang(str, ln)
 * ln defaults naar de tpmLocale variabele die in head.blade.php
 * wordt gevuld met App::getLocale.
 * de translation json objecten voor de talen worden in app.js toegevoegd
 * @param str
 * @param ln
 * @returns {*}
 */
lang = function(str, ln) {
    ln = ln ? ln : tpmLocale;
    return trans[ln][str] ? trans[ln][str] : str;
};
