import { render, staticRenderFns } from "./ConfirmModal.vue?vue&type=template&id=7c8fd1f8&"
import script from "./ConfirmModal.vue?vue&type=script&lang=js&"
export * from "./ConfirmModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./ConfirmModal.vue?vue&type=custom&index=0&blockType=confirm-modal&button-text=%7B%7B%20__('Delete')%20%7D%7D&button-class=btn%20btn-danger&confirm-text=%7B%7B%20__('Confirm')%20%7D%7D&cancel-text=%7B%7B%20__('Cancel')%20%7D%7D&title-text=%7B%7B%20__('Confirm')%20%7D%7D&body-text=%7B%7B%20__('Please%20confirm')%20%7D%7D&submit-route=%2Fadmin%2Fmap%2Fdelete&submit-form=filter-form&csrf=%7B%7B%20csrf_token()%20%7D%7D"
if (typeof block0 === 'function') block0(component)

export default component.exports