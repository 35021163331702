<template>
    <input v-on:click="checkAll" type="checkbox" id="check_all" :checked="this.allChecked">
</template>

<script>
    export default {
        props: {
            // allPages geeft aan of bij het selecteren alle pagina's
            // geselecteerd moeten worden of alleen de items op de huidige pagina
            allPages: {
                type: Boolean,
                default: false
            },
            dataSelector: {
                type: String,
                default: ''
            },
            dataAttr: {
                type: String,
                default: ''
            },
            dataInputId: {
                type: String,
                default: ''
            },
            checked : {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                allChecked: this.checked,
                data: []
            }
        },
        mounted() {
            //debugger;
            //this.updateDataInput();
        },
        methods: {
            checkAll : function(e) {
                let checked = e.target.checked;
                this.setAllChecked(checked);

                this.updateDataInput();
            },
            checkOne: function() {
                // Deze functie wordt van buitenaf aangeroepen, als
                // er op een checkbox van de items in de lijst wordt geklikt.
                // Als niet alle checkboxen meer gechecked zijn,
                // dan de all uitzetten.
                let allOtherChecked = true;
                $(this.dataSelector).each(function(){
                    allOtherChecked = allOtherChecked && this.checked;
                });

                if (this.allChecked && !allOtherChecked) {
                    this.setAllChecked(false);
                    this.allChecked = false;
                }

                this.updateDataInput();
            },
            setAllChecked: function(checked) {
                this.allChecked = checked;

                // Bij allPages = true de links bijwerken met all
                // en #all op 1 zetten
                if (this.allPages) {
                    this.updateLinks(checked);
                    $('#all').attr('value', checked ? 1 : 0);
                }

                $(this.dataSelector).each(function(){
                    this.checked = checked;
                });
            },
            updateDataInput: function() {
                if (this.allChecked && this.allPages) {
                    $('#' + this.dataInputId).attr('value', "all");
                } else {
                    let data = [];
                    let _this = this;
                    $(this.dataSelector + ':checked').each(function () {
                        data.push($(this).attr(_this.dataAttr));
                    });
                    $('#' + this.dataInputId).attr('value', data);
                }
            },
            updateLinks: function(checked) {
                // De parameter 'all' in de links van de paginator moeten geupdate
                // worden met de nieuwe waarde om bij het klikken naar
                // een nieuwe pagina de 'all' te behouden
                $('li.page-item a').each(function() {
                    if (checked) {
                        this.href = this.href.replace('all=0', 'all=1');
                    } else {
                        this.href = this.href.replace('all=1', 'all=0');
                    }
                });
            }
        }
    }
</script>
